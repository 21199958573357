.box-containing-step {
	display: flex;
	flex-flow: column;
	font-family: 'Work Sans', sans-serif;
	padding-bottom: 20px;

	background: #56CCF2;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to top, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to top, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	&.hundred-height {
		height: 100%;
	}

	hr {
		border-bottom: 1px solid #ffffff88;
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.header-divider {
		width: 50%;
	}

	.status-divider {
		width: 70%;
		border-top: 1px solid #ffffff88;
	}

	.base-feeling-text {
		margin-bottom: 20px;
		font-size: calc(20px + 2vmin);
	}

	.conclusion-activity {
		padding: 15px 0 15px 0;
	}

	.conclusion-announce {
		padding: 15px 0 25px 0;
	}

	.conclusion-date {
		font-size: calc(10px + 1vmin);
		font-weight: bold;
	}

	.conclusion-header {
		font-size: calc(20px + 4vmin);
	}

	.conclusion-improvement-text {
		font-size: calc(10px + 1vmin);
		color: #eee;
		margin-top: 10px;
		font-style: italic;
	}

	.conclusion-status-holder {
		padding: 10px 20px 20px 20px;
		box-sizing: border-box;
		color: #ffffffdd;
	}

	.conclusion-subheader {
		font-size: calc(20px + 1vmin);
		color: #fafafa;
		margin-top: 10px;
	}

	.contact-area {
		margin-bottom: 20px;
		padding-top: 15px;
		padding-bottom: 10px;
	}

	.feedback-description {
		color: #fff;
	}

	.feedback-email {
		color: #e2f4ff;
	}

	.status-label-icon {
		font-size: calc(16px + 1vmin);
		margin-right: 5px;
	}

	.status-text {
		display: inline-block;
		font-size: calc(20px + 1vmin);
		color: #eee;
		min-width: 200px;
	}

	.status-text-holder {
		width: 50%;
		display: flex;
	  align-items: center;
	  justify-content: space-around;
	}

	.track-button {
		font-family: 'Work Sans', sans-serif;
		background: #54CDB0;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #47bc9f, #54CDB0);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #47bc9f, #54CDB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
		border: 2px solid #47bc9f;
		width: auto;

		-moz-border-radius: 25px;
		-webkit-border-radius: 25px;
		border-radius: 25px;
		font-size: 20px;
		font-weight: bold;

		&:hover {
			color: #fff;
			background: #2b8770;
			border-color: #2b8770;
		}
	}
}

.conclusion-box {
	background-color: #00000044;
	border-radius: 5px;
	color: #ffffffdd;
	margin-top: 10px;
}

.current-body-scan-part {
	font-weight: bold;
}

.current-feeling-divider {
	border-bottom: 1px solid #fff;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #fff;
	border-top: 1px solid #fff;
}

.done-paragraph {
	width: 50vw;
	margin: 0;
}

.fixed-paragraph {
	min-width: 30vw;
}

.gutter-bottom {
	margin-bottom: 30px;
}

.flexible-width {
	width: 900px;
}

.icon {
	color: #eee;
	height: calc(20px + 1vmin);
	margin-left: 5px;
	cursor: pointer;
	position: relative;
  top: -1vmin;
}

.link {
	color: #d3eaff;
}

.login-area {
	padding: 30px 50px;
	min-width: 250px;
}

.login-page {
	background: #56CCF2;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to top, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to top, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff;
	font-family: 'Work Sans', sans-serif;

	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.login-text {
	font-size: calc(20px + 2vmin);
}

.explainer-text {
	max-width: 400px;
}

.reset-button {
	position: absolute;
	top: 15px;
	right: 20px;
	font-size: calc(20px + 1vw);
	cursor: pointer;
	color: #ffffffee;
}

.select-program-box {
	margin-bottom: 10px;
	box-sizing: border-box;
	padding: 15px 10px 15px 10px;
}

.select-program-explanation {
	margin-top: 20px;
	font-size: calc(15px + 3vmin);
}

.signout-button {
	position: absolute;
	top: 15px;
	right: 20px;
	font-size: calc(8px + 1vw);
	color: #ffffffee;
	label {
		cursor: pointer;
	}
}

.small-text {
	font-size: calc(20px + 2vmin);
}

@media (max-width: 900px){
	.flexible-width {
		width: 96vw;
	}
}

@media (min-width: 900px){
	.fixed-paragraph {
		max-width: 50vw;
	}
}

.status-container {
	padding-top: 15px;
	min-width: 30vw;
}

.status-description {
	margin-top: 20px;
	margin-bottom: 20px;
	width: 300px;
	font-size: calc(12px + 1vmin);
	color: #dcdcdc;
}

.status-label {
	font-size: calc(15px + 2vmin);
	font-family: 'Work Sans', sans-serif;
}

.select-program-buttons {
	margin-top: 20px;
}

.SelectionButton {
	background-color: #ffffff00;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	font-size: 15px;
	font-weight: bold;
	padding: 10px 24px;
	text-decoration: none;
	outline: none;
	width: 200px;
	margin: 10px;
	cursor: pointer;
	border: 2px solid #fff;
	display: inline-block;
	color: #fff;
	&.selected {
		background-color: #fff;
		color: #0784b2;
		cursor: default;
	}
}

.SelectionButton:hover {
	background-color: #fff;
	color: #0784b2;
}

.SelectionButton:active {
	position:relative;
	top:1px;
}

.SelectionButton:disabled {
	background-color: #ddd;
}

.SelectorButton {
	width: 145px;
	margin: 10px 3px;
	padding: 27px 20px;
}

.breath-gif {
	border-radius: 50px;
	border: 1px solid #333;
	max-width: 90%;
}

.talk-it-out-result {
	max-width: 600px;
	min-width: 30vw;
	display: block;
	margin: 0 auto;
	text-align: center;
	background-color: #ffffff22;
	color: #fff;
	border-radius: 5px;
	font-size: calc(10px + 2vmin);
	padding: 10px 20px;
	margin-bottom: 20px;
	border: 2px solid #ffffffaa;
	font-style: italic;
	white-space: pre-line;
}

.talk-it-out-textarea {
	padding: .625em;
	border-radius: 4px;
	border: none;
	height: 250px;

	// TODO DRY up these instances of CSS stuff
	width: 400px;
	@media(max-width: 900px) {
		width: 90vw;
	}
	background-color: #ffffff22;
	color: #fff;
	font-size: 20px;
	margin-left: 10px;
	margin-right: 10px;
}

input.simple-input {
	padding: .625em;
	border-radius: 25px;
	border: 2px solid #fff;
	width: 500px;
	@media(max-width: 900px) {
		width: 90vw;
	}
	background-color: #ffffff00 !important;
	color: #fff;
	font-size: 20px;
	margin-bottom: 20px;

	&::placeholder {
	  color: #ffffffcc;
	  opacity: 1; /* Firefox */
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #ffffffcc;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
		color: #ffffffcc;
	}
	&:focus {
		outline-width: 0;
	}
}

.signup-page {
	// TODO DRY this up with the login page, conclusion page, etc.
	background: #56CCF2;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to top, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to top, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.skip-link {
	font-size: 20px;
	text-decoration-line: underline;
	cursor: pointer;
	color: #ffffffaa;
}

.talk-it-out-textarea:focus {
	outline: none !important;
	border:1px solid #fff;
	box-shadow: 0 0 10px #719ECE;
}

/* <Transitions> */
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	opacity: 1;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* </Transitions> */
