html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

.App {
  text-align: center;
  height: 100%;
  // All of these overflows are a hack to make the backround fullscreen even with Chartjs
  // overflow-y: scroll;
}

.App-header {
  /* https://uigradients.com/#BlueSkies */
  background: #56CCF2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #ffffffdd;

  padding-left: 5px;
  padding-right: 5px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 4vmin);
  font-family: 'Work Sans', sans-serif;
}

.App-link {
  color: #03256C;
}

.flex-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

#root {
  height: 100%;
}
